
span.myLogo {
  font-weight: bold;
  color: #fff;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: normal;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  /* font-family: sans-serif !important; */
  background-image: url('./assets/img/bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
}


p{
  color: #fff;
}


img {
  width: 100%;
  height: auto;
}


nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}



.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  min-height: 600px;

}
.banner h1 {
  /* font-size: max(5vw, 62px); */
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

@media (min-width: 320px) {
  .banner h1{
    font-size: calc(40px + 4 * (100vw - 320px) / 1046);
  }
}
@media (min-width: 1366px) {
  .banner h1 {
    font-size: 65px;
  }
}